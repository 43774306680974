html,
body {
  width: 100%;
  height: 100%;
}
body {
  overflow-y: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #929292;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainIcon{
  border-radius: 50%;
  border: 3px solid #1ab35a;
  background-color: white;
  margin: 5px;
  float: left;
}

a {
  text-decoration: none;
}